import React from 'react'
import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined'
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined'
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined'
import WarehouseOutlinedIcon from '@mui/icons-material/WarehouseOutlined'
import Navigation, { type NavigationData } from './Navigation'
import { useAppSelector } from '../../../redux/hooks'
import { EnvType, KaryonModules } from '../../../redux/user/userTypes'
import retailIcon from '../../../assets/icon/retailIcon.svg'
import fsIcon from '../../../assets/icon/fsIcon.svg'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { listOfFSDistributors } from '../../../pages/private/Foodservice/types'
import StarIcon from '@mui/icons-material/Star';
import { Grid } from '@mui/material'
import { PrimaryText } from '../../ui/Text'
import { BLACK } from '../../ui/Colors'
import { listOfRetailDistributors } from '../../../pages/private/Retail/types'
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import HubOutlinedIcon from '@mui/icons-material/HubOutlined';

const retailLinks = (distributor: string): NavigationData[] => {
    switch (distributor) {
        case listOfRetailDistributors.SELLIN:
            return [{
                id: 0,
                label: 'Tableau de bord',
                link: `/retail/${distributor}/dashboard`,
                icon: <InsightsOutlinedIcon />,
            },]
        default:
            return [
                {
                    id: 0,
                    label: 'Tableau de bord',
                    link: `/retail/${distributor}/dashboard`,
                    icon: <InsightsOutlinedIcon />,
                },
                {
                    id: 1,
                    label: 'Points de vente',
                    link: `/retail/${distributor}/magasins`,
                    icon: <StoreOutlinedIcon />,
                },
                {
                    id: 2,
                    label: 'Références',
                    link: `/retail/${distributor}/produits`,
                    icon: <InventoryOutlinedIcon />,
                },
            ]
    }
}
const fsLinks = (distributor: string, modules: KaryonModules[], companyModules: KaryonModules[]): NavigationData[] => {
    const shouldHavePromoPage = modules.includes(KaryonModules.PROMO) && companyModules.includes(KaryonModules.PROMO)
    const shouldHavePromoDemo = !companyModules.includes(KaryonModules.PROMO)
    switch (distributor) {
        case listOfFSDistributors.FRANCE_FRAIS:
        case listOfFSDistributors.EPISAVEURS:
        case listOfFSDistributors.TRANSGOURMET:
        case listOfFSDistributors.TRANSGOURMETPLUS:
        case 'Commandes':
            return [
                {
                    id: 0,
                    label: 'Tableau de bord',
                    link: `/food-service/${distributor}/dashboard`,
                    icon: <InsightsOutlinedIcon />,
                },
                {
                    id: 1,
                    label: 'Utilisateurs finaux',
                    link: `/food-service/${distributor}/clients`,
                    icon: <StoreOutlinedIcon />,
                },
                {
                    id: 2,
                    label: 'Dépôts',
                    link: `/food-service/${distributor}/depots`,
                    icon: <WarehouseOutlinedIcon />,
                },
                {
                    id: 3,
                    label: 'Références',
                    link: `/food-service/${distributor}/produits`,
                    icon: <InventoryOutlinedIcon />,
                },
                ...(shouldHavePromoPage
                    ? [
                        {
                            id: 4,
                            label: 'Promo',
                            link: `/food-service/${distributor}/promo`,
                            icon: <AttachMoneyIcon />,
                        },
                    ]
                    : []),
                ...(shouldHavePromoDemo ? [{
                    id: 4,
                    label: <Grid container alignItems="center" justifyContent="space-between"><PrimaryText color={BLACK} noWrap style={{ cursor: "pointer" }}>Promo</PrimaryText><StarIcon sx={{ color: "#FFD233", fontSize: "2vmin" }} /></Grid>,
                    link: `/food-service/${distributor}/promoDemo`,
                    icon: <AttachMoneyIcon />,

                }] : [])
            ]

        case listOfFSDistributors.SODEXO:
        case listOfFSDistributors.CERCLEVERT:
            return [
                {
                    id: 0,
                    label: 'Tableau de bord',
                    link: `/food-service/${distributor}/dashboard`,
                    icon: <InsightsOutlinedIcon />,
                },
                {
                    id: 1,
                    label: 'Utilisateurs finaux',
                    link: `/food-service/${distributor}/clients`,
                    icon: <StoreOutlinedIcon />,
                },
                {
                    id: 2,
                    label: 'Références',
                    link: `/food-service/${distributor}/produits`,
                    icon: <InventoryOutlinedIcon />,
                },
            ]
        case listOfFSDistributors.DISTRIBOISSONS:
            return [{
                id: 0,
                label: 'Tableau de bord',
                link: `/food-service/${distributor}/dashboard`,
                icon: <InsightsOutlinedIcon />,
            },]
        case listOfFSDistributors.FRANCEBOISSONS:
            return [{
                id: 0,
                label: 'Tableau de bord',
                link: `/food-service/${distributor}/dashboard`,
                icon: <InsightsOutlinedIcon />,
            },]
        case listOfFSDistributors.GBH:
            return [
                {
                    id: 0,
                    label: 'Tableau de bord',
                    link: `/food-service/${distributor}/dashboard`,
                    icon: <InsightsOutlinedIcon />,
                },
                {
                    id: 1,
                    label: 'Points de vente',
                    link: `/food-service/${distributor}/pointsdevente`,
                    icon: <StoreOutlinedIcon />,
                },
                {
                    id: 2,
                    label: 'Fournisseurs',
                    link: `/food-service/${distributor}/fournisseurs`,
                    icon: <EmojiPeopleIcon />,
                },
                {
                    id: 4,
                    label: 'Références',
                    link: `/food-service/${distributor}/références`,
                    icon: <InventoryOutlinedIcon />,
                },
            ]
        default:
            return [
                {
                    id: 0,
                    label: 'Tableau de bord',
                    link: `/food-service/${distributor}/dashboard`,
                    icon: <InsightsOutlinedIcon />,
                },
                {
                    id: 1,
                    label: 'Utilisateurs finaux',
                    link: `/food-service/${distributor}/clients`,
                    icon: <StoreOutlinedIcon />,
                },
                {
                    id: 2,
                    label: 'Dépôts',
                    link: `/food-service/${distributor}/depots`,
                    icon: <WarehouseOutlinedIcon />,
                },
                {
                    id: 3,
                    label: 'Références',
                    link: `/food-service/${distributor}/produits`,
                    icon: <InventoryOutlinedIcon />,
                },
                ...(shouldHavePromoPage
                    ? [
                        {
                            id: 4,
                            label: 'Promo',
                            link: `/food-service/${distributor}/promo`,
                            icon: <AttachMoneyIcon />,
                        },
                    ]
                    : []),
                ...(shouldHavePromoDemo ? [{
                    id: 4,
                    label: 'Promo',
                    link: `/food-service/${distributor}/promoDemo`,
                    icon: <AttachMoneyIcon />,
                }] : [])
            ]
    }
}

const crossFsLinks = (url: string): NavigationData[] => [
    {
        id: 0,
        label: 'Tableau de bord',
        link: `/food-service/cross/dashboard`,
        icon: <InsightsOutlinedIcon />,
    },
    ...url.includes('alpha') ? [{
        id: 1,
        label: 'Distributeurs',
        link: `/food-service/cross/distributeurs`,
        icon: <HubOutlinedIcon />,
    },
    {
        id: 2,
        label: 'Dépôts',
        link: `/food-service/cross/depots`,
        icon: <WarehouseOutlinedIcon />,
    },
    {
        id: 1,
        label: 'Utilisateurs finaux',
        link: `/food-service/cross/clients`,
        icon: <StoreOutlinedIcon />,
    },
    {
        id: 3,
        label: 'Références',
        link: `/food-service/cross/produits`,
        icon: <InventoryOutlinedIcon />,
    }] : [],
]

const superAdmin = (): NavigationData[] => [
    {
        id: 0,
        label: 'Accueil',
        link: `/multiAccount`,
        icon: <HomeOutlinedIcon />,
    },
]

const crossRetailLinks = (): NavigationData[] => [
    {
        id: 0,
        label: 'Tableau de bord',
        link: `/retail/cross/dashboard`,
        icon: <InsightsOutlinedIcon />,
    },
]

const settingLinks = (): NavigationData[] => [
    {
        id: 0,
        label: 'Food Service',
        link: '/parametres/import-fs',
        icon: <img style={{ width: '2.5vw', height: '2.5vh' }} src={fsIcon} />,
        locked: true,
        lockedTooltip: "[Fonctionnalité à venir] - Actuellement, les imports de données Foodservice se font manuellement. Envoyez directement vos fichiers à votre référente KaryonFood !"
    },
    {
        id: 1,
        label: 'Retail',
        link: '/parametres/import-retail',
        icon: (
            <img style={{ width: '2.5vw', height: '2.5vh' }} src={retailIcon} />
        ),
    },
]

const Navbar = () => {
    const { type } = useAppSelector((state) => state.global.env)
    const { name } = useAppSelector((state) => state.global.env)
    const { user } = useAppSelector((state) => state.user)

    switch (type) {
        case EnvType.FOOD_SERVICE:
            return (
                <Navigation
                    data={fsLinks(
                        name,
                        user.modules, user.companyModules)
                    }
                />
            )
        case EnvType.FOOD_SERVICE_CROSS:
            return <Navigation data={crossFsLinks(window.location.href)} />
        case EnvType.RETAIL_CROSS:
            return <Navigation data={crossRetailLinks()} />
        case EnvType.RETAIl:
            return <Navigation data={retailLinks(name)} />
        case EnvType.SUPER_ADMIN:
            return <Navigation data={superAdmin()} />
        default:
            return <Navigation data={settingLinks()} />
    }
}

export default Navbar
