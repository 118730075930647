import { Grid } from '@mui/material'
import FsKpis, { ConfigKPIs } from './Dashboard/FsKpis'
import React, { useCallback, useState } from 'react'
import DrillDown from '../../../../components/features/drillDowns/DrillDown'
import FsLineChart from './Dashboard/FsLineChart'
import FsSegmentation from './Dashboard/FsSegmentation'
import { HistogramFS } from './Dashboard/HistogramFS'
import { DrillDownType } from '../../../../components/features/drillDowns/ProductsDrillDownContent'
import {
    setDrillDownProducts,
    setDrillDownWarehouses,
} from '../../../../redux/fsProducts/fsProductsSlice'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import {
    EnvName,
    FsFFRKPIs,
    FsSodexoKPIs,
    FsTGMKPIs,
} from '../../../../redux/user/userTypes'
import DashboardDataGrid from './Dashboard/DashboardDataGrid'
import { GridColDef } from '@mui/x-data-grid'

export const DashboardFSPage = ({
    KPIsConfig,
    warehousesConfig,
    hasWarehouseDrilldown = true,
    drilldownTypeClientSegmentation,
    dashboardGridDataTitle,
    segmentationChartTitle,
    drilldownTypeWarehousesTop3
}: {
    KPIsConfig: ({
        KPIs,
        envName,
    }: {
        KPIs: FsTGMKPIs | FsFFRKPIs | FsSodexoKPIs
        envName: EnvName
    }) => ConfigKPIs[]
    warehousesConfig: GridColDef[]
    hasWarehouseDrilldown?: boolean
    drilldownTypeClientSegmentation: DrillDownType
    drilldownTypeWarehousesTop3: DrillDownType
    dashboardGridDataTitle: string
    segmentationChartTitle: string
}) => {
    const [isOpen, setOpen] = useState(false)
    const [fieldSelected, setFieldSelected] = useState<any>(null)
    const { unit } = useAppSelector((state) => state.global)
    const [value, setValue] = useState(0)
    const dispatch = useAppDispatch()
    const { name } = useAppSelector((state) => state.global.env)

    const _setDrillDownWarehouses = useCallback(() => {
        dispatch(setDrillDownWarehouses([]))
    }, [dispatch])

    const _setDrillDownProducts = useCallback(() => {
        dispatch(setDrillDownProducts([]))
    }, [dispatch])

    const handleClose = () => {
        setOpen(false)
        _setDrillDownProducts()
        _setDrillDownWarehouses()
    }
    const dashboardGridData =
        name === EnvName.SODEXO
            ? useAppSelector((state) => state.global.fsDashboardData.categories)
            : useAppSelector((state) => state.global.fsDashboardData.warehouses)


    const dashboardGridDataLink =
        name === EnvName.SODEXO
            ? `/food-service/${name}/clients`
            : `/food-service/${name}/depots`
    return (
        <Grid container direction="column" wrap="nowrap" height="90vh" p="1vw">
            <FsKpis KPIsConfig={KPIsConfig} />
            <Grid
                container
                direction="column"
                wrap="nowrap"
                justifyContent="flex-start"
                pt="1vw"
                sx={{ height: '80vh' }}
            >
                <DrillDown
                    type={drilldownTypeClientSegmentation}
                    open={isOpen}
                    title={fieldSelected && fieldSelected.id}
                    value={value}
                    setValue={setValue}
                    row={fieldSelected}
                    handleClose={handleClose}
                />
                <Grid item sx={{ height: '40%' }}>
                    <FsLineChart />
                </Grid>
                <Grid container wrap="nowrap" pt="1vw" sx={{ height: '60%' }}>
                    <Grid
                        item
                        flex={1.5}
                        sx={{ height: '100%', width: '14vw' }}
                    >
                        <FsSegmentation
                            title={segmentationChartTitle}
                            setOpen={setOpen}
                            setFieldSelected={setFieldSelected}
                        />
                    </Grid>
                    <Grid
                        container
                        flexWrap="nowrap"
                        direction="column"
                        flex={2}
                        pl="1vw"
                        sx={{ height: '100%' }}
                    >
                        <Grid item flex={1.5} mb="1vw">
                            <HistogramFS title={`Volumes vendus par rapport à A-1 (${unit})`} />
                        </Grid>
                        <Grid item flex={1.5} height="100%">
                            <DashboardDataGrid
                                columns={warehousesConfig}
                                drilldown={hasWarehouseDrilldown}
                                data={dashboardGridData}
                                title={dashboardGridDataTitle}
                                link={dashboardGridDataLink}
                                drilldownType={drilldownTypeWarehousesTop3}
                                envName={name}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
